<template>
  <div class="left_header">
    <a :class="className[0]" @click="$router.push('/')">home</a>
    <a :class="className[1]" @click="$router.push('/calendar')">calendar</a>
  </div>
</template>

<script>
export default {
  setup(_, context) {
    const name = context.root.$route.name;

    const className = [
      `menu fontEn ${name === 'main' ? 'on' : ''}`,
      `menu fontEn ${name === 'calendar' ? 'on' : ''}`,
    ];

    return { className };
  },
  created() {
    this.$route;
  },
};
</script>

<style></style>
