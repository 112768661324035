<template>
  <header class="layout_header">
    <div class="hinner">
      <nav class="gnb">
        <home-calendar-nav />
        <search-cart-icons />
      </nav>
    </div>
    <slot />
  </header>
</template>

<script>
import HomeCalendarNav from './components/LeftHeader/HomeCalendarNav.vue';
import SearchCartIcons from './components/RightHeader/SearchCartIcons.vue';

export default {
  components: { HomeCalendarNav, SearchCartIcons },
};
</script>

<style></style>
