<template>
  <div class="wrap-calendar">
    <h3 class="text-center">{{ title }}</h3>
    <header>
      <span>Sun</span>
      <span>Mon</span>
      <span>Tue</span>
      <span>Wed</span>
      <span>Thu</span>
      <span>Fri</span>
      <span>Sat</span>
    </header>
    <section>
      <template v-for="(days, idx) in weeks">
        <article :key="idx">
          <template v-for="(day, sIdx) in days">
            <div
              :key="`day-${sIdx}`"
              :class="day.yyyymmdd && day.yyyymmdd === selected ? 'active' : ''"
              @click="handleClick(day)"
            >
              <span>{{ day.label }}</span>
              <i v-if="events[day.yyyymmdd]" />
            </div>
          </template>
        </article>
      </template>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import { reactive, toRefs } from 'vue-demi';

export default {
  props: ['yyyymm', 'selected', 'beforeCreate'],
  setup(props, context) {
    const last = moment(props.yyyymm).endOf('month');
    const start = moment(props.yyyymm).startOf('month');

    const weeks = [[]];

    const state = reactive({
      title: start.format('MMM YYYY'),
      events: {},
    });

    props.beforeCreate?.(state, props.yyyymm);

    let position = 0;

    for (let i = 0; i < start.day(); i++) {
      weeks[position].push({
        label: '',
      });
    }

    for (let i = 1; i <= last.date(); i++) {
      const day = `0${i}`.substr(-2);
      const yyyymmdd = `${props.yyyymm}${day}`;

      weeks[position].push({ label: i, yyyymmdd });

      if (i > 0 && weeks[position].length % 7 === 0) {
        position++;
        weeks[position] = [];
      }
    }

    while (weeks[position].length % 7 !== 0) {
      weeks[position].push({
        label: '',
      });
    }

    return {
      weeks,
      ...toRefs(state),
      handleClick(item) {
        if (!state.events?.[item.yyyymmdd]) return;

        context.emit('select', state.events[item.yyyymmdd].cbe_id);
      },
    };
  },
};
</script>

<style scoped>
.wrap-calendar {
  padding: 14px;
}

.wrap-calendar > header,
.wrap-calendar > section > article {
  display: flex;
  justify-content: space-between;
}
.wrap-calendar > header > span {
  display: block;
  width: 14%;
  padding: 20px 0;
  text-align: center;
}
.wrap-calendar > section > article > div {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.wrap-calendar > section > article > div:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.wrap-calendar > section > article > div.active {
  background: #000;
}
.wrap-calendar > section > article > div.active span {
  color: #fff;
}
.wrap-calendar > section > article > div > span {
  font-size: 12px;
  position: absolute;
}
.wrap-calendar > section > article > div > i {
  width: 3px;
  height: 3px;
  position: absolute;
  background: #000;
  bottom: 4px;
  border-radius: 10px;
}
.wrap-calendar > section > article > div.active i {
  background: #fff;
}
/* .wrap-calendar > section > article > div.active > i {
  background: #fff;
} */
</style>
