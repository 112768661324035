<template>
  <router-link
    to="/search/main"
    class="menu serch"
    :title="$t('Layout.SearchBtn')"
  />
</template>

<script>
export default {};
</script>

<style></style>
