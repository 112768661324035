<template>
  <div class="layout_body box_p60" @click="handleCloseAllCalendar">
    <header-title-basket />
    <section class="calendar_wrap">
      <p class="select_box">
        <button
          class="btn dwn_arr btn_pop_open font2"
          type="button"
          @click="isShowSortOption = true"
        >
          {{ tagReturn(is_option) }}
        </button>
      </p>
      <!-- 월 단위 반복 -->
      <div
        v-for="month in monthList"
        :key="`title-${month}`"
        :class="`${isLoading ? '' : 'off'} calendar_wrap`"
      >
        <div class="title_box block btwlist">
          <p class="con">
            <button
              :data-yyyymm="month"
              class="box dwn_arr"
              type="button"
              @click="handleClickMonthLabel(month)"
            >
              {{ events[month].label }}
            </button>
          </p>
          <calendar-slider
            v-if="isCalendarShow[month]"
            :beforeCreate="handleBeforeCreateMonth"
            :date="`${month}01`"
            :selected="selectedDate"
            @select="handleSelectDate"
          />
        </div>
        <article class="calendar_month">
          <ul class="cald_list">
            <li
              v-for="event in events[month].events"
              :key="event.cbe_id"
              :class="`cbe_id_${event.cbe_id}`"
            >
              <div class="data_box btwlist">
                <p class="data con">
                  <span :class="event.labelClassName">{{ event.label }}</span>
                  <span class="date">{{ event.period }}</span>
                </p>
                <p class="info con r">
                  <alarm-button
                    :id="event.cbe_id"
                    :isPush="event.is_push"
                    table="cmall_board_event"
                  />
                </p>
              </div>
              <router-link :to="`/event/${event.cbe_id}`" class="info_box">
                <h3 class="title">{{ event.cbe_title }}</h3>
                <image-block-src
                  :alt="event.cbe_title"
                  :src="event.main_img"
                  :title="event.cbe_title"
                />
              </router-link>
              <div v-if="event.is_option.includes(',')">
                <em
                  v-for="(tagName, idx) in arrayTagName(event.is_option)"
                  :key="idx"
                  class="tag"
                  >{{ tagReturn(tagName) }}</em
                >
              </div>
              <em v-else class="tag">{{ tagReturn(event.is_option) }}</em>
            </li>
          </ul>
        </article>
      </div>
      <div v-if="!isLoading" class="loading_wrap">
        <div class="loading_content">
          <img alt="loading" src="../../assets/images/design/loading.gif" />
        </div>
      </div>

      <infinite-loading
        v-if="hasMore"
        :class="`${isLoading ? '' : 'off'}`"
        :identifier="infiniteId"
        @infinite="loadDatas"
      />

      <div class="btn_top">
        <button
          class="top_move flex_right"
          type="button"
          @click="handleClickTop"
        ></button>
      </div>
      <sild-footer />

      <sort-option-popup
        v-if="isShowSortOption"
        :items="keyLabels"
        :selected-key="is_option"
        @close="isShowSortOption = false"
        @select="handleSelectSortValue"
      />
    </section>
  </div>
</template>

<script>
import HeaderTitleBasket from '@Layouts/components/headers/HeaderTitleBasket.vue';
import EventAPI from '@/apis/EventAPI';
import { reactive, toRefs } from 'vue-demi';
import moment from 'moment';
import ImageBlockSrc from '../../components/ui/ImageBlock/ImageBlockSrc.vue';
import CalendarSlider from '../../components/ui/Calendar/CalendarSlider.vue';
import AlarmButton from '../../components/ui/Buttons/AlarmButton.vue';
import useEventInfos from '@/composables/events/useEventInfos';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import SortOptionPopup from './popups/SortOptionPopup.vue';
import InfiniteLoading from 'vue-infinite-loading';
import useUserInfo from '@Composables/user/useUserInfo';

export default {
  components: {
    HeaderTitleBasket,
    ImageBlockSrc,
    CalendarSlider,
    AlarmButton,
    SildFooter,
    SortOptionPopup,
    InfiniteLoading,
  },
  setup(_, context) {
    const { getEventsByMonth } = useEventInfos();

    const state = reactive({
      events: null,
      hasMore: true,
      infiniteId: Date.now(),
      selectedDate: moment().format('YYYYMMDD'),
      isCalendarShow: {},
      isShowSortOption: false,
      page: 0,
      is_option: localStorage.getItem('is_option'),
      monthList: [],
      isLoading: false,
    });

    const { info } = useUserInfo();
    const loadDatas = async attr => {
      const { data } = await EventAPI.getEventList({
        offset: state.page * 10,
        order_by: 'start',
        mem_id: info?.mem_id,
        is_option: state.is_option ?? '',
      });
      console.log('켈린더_데이터');
      console.log(data);

      if (data.list.length === 0) {
        attr?.complete();
        state.hasMore = false;
      } else {
        setDatas(data.list);
        state.page++;
        attr?.loaded();
      }
      state.isLoading = true;
    };

    const getDateClassName = day => {
      switch (day) {
        case 6:
          return 'sat';
        case 0:
          return 'sun';
        default:
          return '';
      }
    };

    const setDatas = items => {
      const events = {};

      items.forEach(item => {
        const dates = item.start_date.split('-');
        const yyyymm = `${dates[0]}${dates[1]}`;
        const eventStartDate = item.start_date;
        const eventEndDate = item.end_date;
        const eventStartTime =
          item.start_time !== '' ? item.start_time : '00:00';
        const eventEndTime = item.end_time !== '' ? item.end_time : '00:00';
        const date = moment(`${eventStartDate} ${eventStartTime}`);
        const eDate = moment(`${eventEndDate} ${eventEndTime}`);

        if (!events[yyyymm])
          events[yyyymm] = {
            yyyymm,
            events: [],
            label: date.format(context.root.$t('Brand.Date')),
          };

        const event = {
          ...item,
          label: date.format('DD일 A HH 시 mm 분 ddd').toUpperCase(),
          labelClassName: `line_box gr ${getDateClassName(date.day())}`,
          alramClassName: `imgalram ${item.is_push === 'y' ? 'on' : ''}`,
          period: `${date.format('MM월 DD일 A HH 시 mm 분')} - ${eDate.format(
            'MM월 DD일 A HH 시 mm 분',
          )}`,
        };

        events[yyyymm]['events'].push(event);
      });
      if (state.page === 0) {
        state.monthList = Object.keys(events)
          .map(n => Number(n))
          .sort((a, b) => b - a);
      } else {
        state.monthList = Array.from(
          new Set([
            ...state.monthList,
            ...Object.keys(events).map(n => Number(n)),
          ]),
        ).sort((a, b) => b - a);
      }
      state.events = events;
    };

    const closeAllCalendar = exception => {
      const newIsCalendarShow = { ...state.isCalendarShow };

      Object.keys(newIsCalendarShow).forEach(key => {
        if (key !== exception) {
          newIsCalendarShow[key] = false;
        }
      });

      state.isCalendarShow = newIsCalendarShow;
    };

    // 키워드 태그 네임
    const tagReturn = is_option => {
      let tagName = ``;
      if (is_option === 'new') {
        tagName = context.root.$t('Brand.TagNew');
      } else if (is_option === 'sale') {
        tagName = context.root.$t('Brand.TagSale');
      } else if (is_option === 'free') {
        tagName = context.root.$t('Brand.TagFree');
      } else if (is_option === 'store') {
        tagName = context.root.$t('Brand.TagStore');
      } else if (is_option === 'etc') {
        tagName = context.root.$t('Brand.TagEct');
      } else {
        tagName = context.root.$t('Brand.TagAll');
      }
      return tagName;
    };

    return {
      ...toRefs(state),
      loadDatas,
      tagReturn,
      handleClickMonthLabel(yyyymm) {
        const newIsCalendarShow = { ...state.isCalendarShow };

        newIsCalendarShow[yyyymm] = !newIsCalendarShow[yyyymm];

        state.isCalendarShow = newIsCalendarShow;
      },
      async handleSelectDate(id) {
        const targetDom = window.document.getElementsByClassName(
          `cbe_id_${id}`,
        )[0];
        targetDom.scrollIntoView({ behavior: 'smooth' });

        closeAllCalendar();
      },
      arrayTagName(is_option) {
        return is_option.split(',');
      },
      handleCloseAllCalendar(e) {
        let isCalendarClick = false;

        e.path.forEach(node => {
          if (isCalendarClick === false)
            isCalendarClick = node.className === 'calendar-slider';
        });

        if (isCalendarClick === false) {
          const yyyymm = e.path[0]?.getAttribute('data-yyyymm');

          closeAllCalendar(yyyymm);
        }
      },
      async handleBeforeCreateMonth(mState, yyyymm) {
        const events = { ...mState.events };

        const data = await getEventsByMonth(yyyymm);

        data.forEach(event => {
          const start_date = event.start_date.replace(/-/g, '');

          events[start_date] = event;
        });

        mState.events = events;
      },

      handleSelectSortValue(item) {
        state.isShowSortOption = false;
        state.is_option = item.key;
        state.infiniteId = Date.now();
        state.hasMore = null;
        state.page = 0;
        loadDatas();

        localStorage.setItem('is_option', item.key);
      },

      handleClickTop() {
        window.scrollTo(0, 0);
      },
    };
  },
};
</script>

<style>
.btwlist {
  position: relative;
}
.btwlist .calendar-slider {
  background-color: #fff;
  left: 0;
  position: absolute;
  top: 42px;
  z-index: 100;
}
.tag {
  background-color: #efefef;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  line-height: 18px;
  margin-right: 4px;
  padding: 0 7px;
}
.calendar_wrap.off {
  opacity: 0;
}
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  background: transparent;
  height: calc(100% - 90px);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.loading_content img {
  border-radius: 50em;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  z-index: 100;
}
@media (min-width: 1240px) {
  .loading_content {
    left: 50%;
    max-width: 360px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.loading_content {
  background: #fff;
  z-index: 99;
}
</style>
