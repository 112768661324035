<template>
  <footer v-if="isToggleNav" class="layout_footer nav-down">
    <nav class="btn_gnb distable fontEn">
      <router-link
        v-for="menu in menus"
        :key="menu.link"
        :to="menu.link"
        :title="menu.title"
        :class="menu.className"
      >
        {{ menu.label }}
      </router-link>
    </nav>
  </footer>
  <footer v-else class="layout_footer nav-down">
    <nav class="btn_gnb distable fontEn">
      <router-link
        v-for="menu in menus"
        :key="menu.link"
        :to="menu.link"
        :title="menu.title"
        :class="menu.className"
      >
        {{ menu.label }}
      </router-link>
    </nav>
  </footer>
</template>

<script>
import { newScrollEvent, toggleNav } from '@/utils/GlobalUtils';
import { ref } from 'vue-demi';

export default {
  data() {
    return {
      msg: true,
    };
  },
  setup(props, context) {
    const { name } = context.root.$route;
    const names = name?.split('.') ?? [];
    let isScroll = ref(false);

    const menus = [
      {
        label: 'HOME',
        name: 'main',
        link: '/',
        title: '홈',
        className: 'foot_btn hoem con',
      },
      {
        label: 'BRAND',
        name: 'brand',
        link: '/brand',
        title: '브랜드',
        className: 'foot_btn brands con',
      },
      {
        label: 'ITEMS',
        name: 'items',
        link: '/items',
        title: '아이템',
        className: 'foot_btn items con',
      },
      {
        label: 'PICK',
        name: 'pick',
        link: '/pick',
        title: '픽',
        className: 'foot_btn pick con',
      },
      {
        label: 'MY',
        name: 'mypage',
        link: '/mypage',
        title: '마이페이지',
        className: 'foot_btn mypage con',
      },
    ];
    menus.forEach(menu => {
      const isCalendarPage = menu.name === 'main' && names[0] === 'calendar';
      if (menu.name === names[0] || isCalendarPage) {
        menu.className += ` on`;
      }
    });
    newScrollEvent(ref(isScroll));
    return { menus, isScroll };
  },
  props: ['isToggleNav'],
  created() {
    this.isToggleNav && window.addEventListener('scroll', toggleNav);
  },
  destroyed() {
    this.isToggleNav && window.removeEventListener('scroll', toggleNav);
  },
};
</script>

<style>
.isToogleNav.layout_footer {
  top: auto !important;
  transition: bottom 0.2s ease-in-out !important;
}
.layout_footer.nav-down {
  bottom: 0px;
}
.layout_footer.nav-up {
  bottom: -60px;
}
</style>
