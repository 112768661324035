import { defineStore } from 'pinia';
import EventAPI from '@/apis/EventAPI';

export default defineStore('useEventInfos', () => {
  const eventsOfDay = {};
  const eventsOfMonth = {};

  const updateEventInfo = async yyyymm => {
    const { data } = await EventAPI.getEventInfoByMonth({
      yyyymmdd: `${yyyymm}01`,
    });

    eventsOfMonth[yyyymm] = data.data;

    data.data.forEach(event => {
      const yyyymmdd = event.start_date.replace(/-/g, '');

      if (!eventsOfDay[yyyymmdd]) eventsOfDay[yyyymmdd] = [];

      eventsOfDay[yyyymmdd].push(event);
    });
  };

  const getEventsByMonth = async yyyymm => {
    if (!eventsOfMonth[yyyymm]) await updateEventInfo(yyyymm);

    return eventsOfMonth[yyyymm];
  };

  const getEventsByDay = yyyymmdd => {
    return eventsOfDay[yyyymmdd];
  };

  return { getEventsByMonth, getEventsByDay, updateEventInfo };
});
