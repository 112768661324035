<template>
  <img :src="file_src" />
</template>

<script>
export default {
  props: ['src'],
  setup(props) {
    return {
      file_src: `${process.env.VUE_APP_FILE_URL}${props.src}`,
    };
  },
};
</script>

<style></style>
