<template>
  <button type="button" :class="className" @click="handleClick" />
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import api from '@/apis/CommonAPI';
import useToast from '@/composables/dialogs/useToast';
import useUserInfo from '@Composables/user/useUserInfo';
import useAlert from '@Composables/dialogs/useAlert';

export default {
  props: ['isPush', 'table', 'id'],
  setup(props, context) {
    const { showAlert } = useAlert();
    const { addMsg } = useToast();
    const state = reactive({
      className: 'imgalram',
      isPushed: props.isPush,
    });
    const { info } = useUserInfo();
    const setClassName = () => {
      state.className = `imgalram ${state.isPushed === 'y' ? 'on' : ''}`;
    };

    setClassName();

    const setPushValue = async () => {
      await api.setPush({
        target_id: props.id,
        taget_table: props.table,
        is_push: state.isPushed,
        mem_id: info?.mem_id,
      });

      addMsg(
        context.root.$t('Button.AlertNotice', {
          Set:
            state.isPushed === 'y'
              ? context.root.$('Button.Add')
              : context.root.$('Button.Delete'),
        }),
      );
    };

    return {
      ...toRefs(state),
      handleClick() {
        if (localStorage.getItem('mem_id')) {
          state.isPushed = state.isPushed === 'y' ? 'n' : 'y';

          setClassName();
          setPushValue();
        } else {
          showAlert(context.root.$t('Join.AlertLogin'));
        }
      },
    };
  },
};
</script>

<style></style>
