<template>
  <down-up-popup :title="$t('Brand.OptionSelect')" @close="$emit('close')">
    {{ keyLabels }}

    <ul slot="body" class="select_list_box">
      <li v-for="item in keyLabels" :key="item.label">
        <i v-if="selectedKey === item.key" class="selected" />
        <button type="button" @click="$emit('select', item)">
          {{ item.label }}
        </button>
      </li>
    </ul>

    <template slot="footer">
      <span />
    </template>
  </down-up-popup>
</template>

<script>
import DownUpPopup from '@/components/popups/DownUpPopup.vue';

export default {
  props: ['selectedKey'],
  components: { DownUpPopup },

  setup(_, context) {
    const keyLabels = [
      {
        key: null,
        label: context.root.$t('Brand.TagAll'),
      },
      {
        key: 'new',
        label: context.root.$t('Brand.TagNew'),
      },
      {
        key: 'sale',
        label: context.root.$t('Brand.TagSale'),
      },
      {
        key: 'free',
        label: context.root.$t('Brand.TagFree'),
      },
      {
        key: 'store',
        label: context.root.$t('Brand.TagStore'),
      },
      {
        key: 'etc',
        label: context.root.$t('Brand.TagEct'),
      },
    ];
    return {
      keyLabels,
    };
  },
};
</script>

<style scoped>
.select_list_box li {
  position: relative;
}
i.selected {
  position: absolute;
  left: -15px;
  top: 21px;
  width: 3px;
  height: 3px;
  background: #000;
  bottom: 4px;
  border-radius: 10px;
}
</style>
