<template>
  <div class="right_header">
    <search-icon />
    <basket-button />
  </div>
</template>

<script>
import BasketButton from '../BasketButton/BasketButton.vue';
import SearchIcon from './SearchIcon.vue';

export default {
  components: { SearchIcon, BasketButton },
};
</script>

<style></style>
