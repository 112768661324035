<template>
  <div class="calendar-slider">
    <header slot="navigation">
      <button type="button" class="swiper-prev">
        <img :src="loadImage('leftarrow_imgbox.svg')" alt="" srcset="" />
      </button>
      <button type="button" class="swiper-next">
        <img :src="loadImage('righttarrow_img_box.svg')" alt="" srcset="" />
      </button>
    </header>
    <swiper :options="swiperOption2" style="overflow: hidden">
      <swiper-slide v-for="month in months" :key="month">
        <month-calendar
          :yyyymm="month"
          :selected="selected"
          :beforeCreate="beforeCreate"
          @select="handleSelect"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import MonthCalendar from './MonthCalendar.vue';
import moment from 'moment';
import { reactive, toRefs } from 'vue-demi';
import { loadImage } from '@/utils/CommonUtils';

export default {
  props: ['date', 'beforeCreate', 'selected'],
  components: { Swiper, SwiperSlide, MonthCalendar },
  setup(props, context) {
    const date = moment(props.date).subtract(2, 'months');
    const state = reactive({
      months: [],
    });

    for (let i = 0; i < 5; i++) {
      state.months.push(date.format('YYYYMM'));

      date.add(1, 'months');
    }

    let initialSlideEnd = false;

    const width = document.body.clientWidth;

    return {
      width,
      ...toRefs(state),
      loadImage,
      swiperOption2: {
        class: 'mySwiper',
        initialSlide: 2,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
        on: {
          slideChangeTransitionStart() {
            this.allowTouchMove = false;
            this.allowSlidePrev = false;
            this.allowSlideNext = false;
          },
          transitionEnd() {
            this.allowTouchMove = true;
            this.allowSlidePrev = true;
            this.allowSlideNext = true;
          },

          slideNextTransitionEnd() {
            if (!initialSlideEnd) {
              initialSlideEnd = true;
              return;
            }

            const yyyymm = state.months[state.months.length - 1];

            const next = moment(`${yyyymm}01`).add(1, 'months');
            const nextMonths = [...state.months].slice(1, 5);

            nextMonths.push(next.format('YYYYMM'));
            state.months = nextMonths;

            this.activeIndex = 2;
          },

          slidePrevTransitionEnd() {
            const yyyymm = state.months[0];
            const months = [...state.months];

            const prev = moment(`${yyyymm}01`).subtract(1, 'months');
            const prevMonths = months.slice(0, months.length - 1);

            state.months = [prev.format('YYYYMM'), ...prevMonths];

            this.activeIndex = 2;
          },
        },
      },
      handleSelect(id) {
        context.emit('select', id);
      },
    };
  },
};
</script>

<style scoped>
.calendar-slider {
  width: 100%;
  animation: fadeInDown 0.5s;
}
header.disflex {
  justify-content: space-between;
}
.calendar-slider > header button {
  position: absolute;
  top: 14px;
  z-index: 110;
}
.calendar-slider > header .swiper-prev {
  left: 14px;
}
.calendar-slider > header .swiper-next {
  right: 14px;
}
</style>
