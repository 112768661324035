import { axios } from "@/plugins/axios";

export default {
  getEventList(params) {
    return axios.post("event/ajaxEventList", params);
  },
  getEventInfoByMonth(params) {
    return axios.post("event/ajaxGetEventInfoByMonth", params);
  },
  getEventDetail(params) {
    return axios.post("event/detail", params);
  },
  getMainPopupList() {
    return axios.post("popup/getMainPopupList");
  },
};
